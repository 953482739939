<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportHasilKompetensi from "./report-hasil-kompetensi.vue";
import ReportHasilJawaban from "./report-hasil-jawaban.vue";
import ReportHasilSurvei from "./report-hasil-survei.vue";
import ReportHasilEsai from "./report-hasil-esai.vue";

export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportHasilKompetensi,
    ReportHasilJawaban,
    ReportHasilSurvei,
    ReportHasilEsai,
  },
  data() {
    return {
      title: "Report Hasil",
    };
  },
  mounted() {},
  methods: {},
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="Kompetensi Peserta" active>
              <ReportHasilKompetensi />
            </b-tab>
            <b-tab title="Jawaban Peserta" lazy>
              <b-card-text>
                <ReportHasilJawaban />
              </b-card-text>
            </b-tab>
            <b-tab title="Survei Peserta" lazy>
              <b-card-text>
                <ReportHasilSurvei />
              </b-card-text>
            </b-tab>
            <b-tab title="Jawaban Esai" lazy>
              <b-card-text>
                <ReportHasilEsai />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
